<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">
        <i @click="goBack" class="el-icon-back">返回</i>
      </el-breadcrumb-item>
      <el-breadcrumb-item>创建资讯</el-breadcrumb-item>
    </el-breadcrumb>
    <p class="hh_title">创建资讯</p>
    <div class="hh_info_wrap">
      <el-form
        class="form"
        :model="formData"
        :rules="rules"
        ref="form"
        :inline="true"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="标题" prop="newsTitle">
              <el-input
                v-model.trim="formData.newsTitle"
                placeholder="请输入资讯标题"
                maxlength="120"
                show-word-limit
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="wxq_upload" label="封面图" prop="imgUrl">
              <up-loads
                :callbackList="imgUrl"
                :upStyle="'btnList'"
                ref="isShow"
                @up-success="upLoadImgUrls"
              ></up-loads>
              <el-col :span="12">
                <el-form-item class="hh_selet" label="分类" prop="newsType">
                  <el-select v-model="formData.newsType" placeholder="请选择">
                    <el-option
                      v-for="item in categoryList"
                      :key="item.dictCode"
                      :label="item.dictLabel"
                      :value="item.dictLabel"
                      :disabled="item.status == 0"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-col :span="16">
                  <el-form-item class="hh_witer" label="作者" prop="newsAuthor">
                    <el-input
                      v-model.trim="formData.newsAuthor"
                      placeholder="请输入作者"
                      maxlength="8"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="wxq_editor" label="编辑文稿" prop="newsText">
              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="shangchuan"
                v-model="formData.newsText"
              ></vue-editor>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="摘要" prop="newsDesc">
              <el-input
                v-model.trim="formData.newsDesc"
                placeholder="选填，摘要帮助读者快速了解内容，如不填写则默认抓取正文前120字"
                maxlength="120"
                show-word-limit
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div class="dialog_submit">
        <el-button class="btn btn_green" @click="getSave('form')"
          >保存草稿</el-button
        >
        <el-button class="btn btn_blue" @click="getSave('form', 2)"
          >提交审核</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import API from "@/apis/backStageEndAPI/consultManageAPI";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import upLoads from "@/components/backStageComponent/upLoads";
import uploadAPI from "@/apis/common";
import { VueEditor } from "vue2-editor";
var COS = require("cos-js-sdk-v5");
var cos = new COS({
  getAuthorization: async function (options, callback) {
    // console.log(options); m
    // 异步获取临时密钥
    await uploadAPI.getCosToken().then((data) => {
      if (!data.credentials) return console.error("credentials invalid");
      var credentials = data && data.credentials;
      this.resultData = data;
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    });
  },
});
export default {
  name: "addConsult",
  components: {
    headerBar,
    upLoads,
    VueEditor,
  },
  data() {
    return {
      Bucket: "slyl-edu-static-1301295327",
      Region: "ap-beijing",
      id: "",
      imgUrl: "",
      categoryList: [],
      formData: {
        newsTitle: "",
        imgUrl: "",
        newsAuthor: "",
        // newsDesc: "",
        newsType: "",
        newsText: "",
        state: "",
      },
      rules: {
        newsTitle: [
          { required: true, message: "请输入资讯标题", trigger: "blur" },
        ],
        newsText: [{ required: true, message: "请输入正文", trigger: "blur" }],
        newsAuthor: [
          { required: true, message: "请输入作者", trigger: "blur" },
        ],
        imgUrl: [
          { required: true, message: "请选择封面图片", trigger: "change" },
        ],
        newsType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getCourseLabel();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getConsultDetail(this.id);
    }
  },
  methods: {
    getConsultDetail(id) {
      API.detail(id).then((res) => {
        this.formData = res;
        this.imgUrl = res.imgUrl.split(",");
        this.$refs.isShow.isShow = this.imgUrl.length === 0;
      });
    },
    getCourseLabel() {
      //资讯分类
      dictionaryAPI.getDicData('consult_code').then((res) => {
        this.categoryList = res;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getSave(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.state = val ? val : 0;
          if (this.id) {
            //有id调编辑接口，无id调新建接口
            this.formData.id = this.id;
            API.editConsult(this.formData).then((res) => {
              this.$router.push("consultManagement");
            });
          } else {
            API.addConsult(this.formData).then((res) => {
              this.$router.push("consultManagement");
            });
          }
        } else {
          return false;
        }
      });
    },
    upLoadImgUrls(val) {
      //上传背景图
      this.imgUrl = val;
      this.formData.imgUrl = val.join(",");
      this.$refs.isShow.isShow = this.imgUrl.length === 0;
    },
    shangchuan(e, Editor, cursorLocation, resetUploader) {
      var _this = this;
      var file = e;
      if (!file) return;
      let suffixName = file.name.substring(
        file.name.lastIndexOf(".") + 1
      ); /* 得到文件后缀名 */
      let index = file.name.indexOf("."),
        name = file.name.substr(0, index) + new Date().getTime(),
        fileName = this.$md5(name) + "." + suffixName;
      cos.putObject(
        {
          //上传对象
          Bucket: this.Bucket, //存储桶
          Region: this.Region, //存储桶所在地域
          StorageClass: "STANDARD",
          Key: "/upload/images/" + fileName,
          // Key: "/upload/images/" + file.name,
          Body: file,
        },
        function (err, data) {
          // _this.progressVisible = false;
          cos.getObjectUrl(
            {
              //获取不带签名的对象的 Url
              Bucket: _this.Bucket,
              Region: _this.Region,
              Key: "/upload/images/" + fileName,
              // Key: "/upload/images/" + file.name,
              Sign: false,
            },
            function (err, data) {
              Editor.insertEmbed(cursorLocation, "image", data.Url);
              resetUploader();
            }
          );
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .ql-snow .ql-editor img{
    width: 88px;
    height: 88px;
    max-width: 100%;
  }
  .quillWrapper .ql-snow.ql-toolbar{
    border-radius: 18px;
  }
  .ql-container.ql-snow {
    border-radius: 18px;
  }
}
.hh_title {
  margin: 8px 8px;
  font-weight: 800;
  font-size: 24px;
}
.hh_info_wrap {
  background: #ffffff;
  padding: 10px 0;
  border-radius: 24px;
  ::v-deep .form {
    margin: 0px auto;
    width: 82%;
    padding: 10px 0;
    .el-textarea__inner {
      border-radius: 8px;
    }
    .hh_selet {
      width: 66.5% !important;
      .el-input {
        width: 100% !important;
      }
      .el-form-item__error {
        margin-top: 0;
      }
    }
    .hh_witer {
      margin-top: 20px;
      .el-form-item__content {
        padding-left: 90px !important;
      }
      .el-form-item__error {
        padding-left: 90px;
        margin-top: 0;
      }
      .el-form-item__label {
        left: 37px;
        text-align: left;
        width: 60px;
      }
    }
  }
  ::v-deep .wxq_upload {
    .el-form-item__content {
      display: flex;
    }
    .el-form-item__error {
      margin-top: -14px;
    }
    .upLoad_list_big {
      width: 280px;
      height: 144px;
      border-radius: 8px !important;
    }
  }
  .dialog_submit {
    text-align: center;
    .btn_green {
      background: #00d097;
    }
  }
}
.wxq_editor {
  margin-top: -17px;
  ::v-deep .el-form-item__content {
    height: 500px;
  }
  .quillWrapper {
    height: 445px;
  }
  ::v-deep.el-form-item__content {
    padding-left: 0;
  }
}
</style>
