import { render, staticRenderFns } from "./addConsult.vue?vue&type=template&id=0dd20a29&scoped=true"
import script from "./addConsult.vue?vue&type=script&lang=js"
export * from "./addConsult.vue?vue&type=script&lang=js"
import style0 from "./addConsult.vue?vue&type=style&index=0&id=0dd20a29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd20a29",
  null
  
)

export default component.exports